import React from 'react'
import PropTypes from 'prop-types'

import {
  Icon,
} from './styles'

const IconCustom = ({
  theme, ...props
}) => <Icon theme={theme} {...props} />

IconCustom.propTypes = {
  theme: PropTypes.oneOf([
    'airplane',
    'check',
    'cost',
    'everyone',
    'hello',
    'target',
    'time',
    'handshake',
    'talkcheck',
    'lock',
    'languages',
    'thumbsup',
    'first',
    'second',
    'third',
    'providerAccessInterviews',
    'providerCopyToEpic',
    'frontDesk',
  ]),
}

IconCustom.defaultProps = {
  theme: 'check',
}

export default IconCustom
