import styled from 'styled-components'

import IconCheck from '../../../../assets/images/icons-custom/icon-Check.svg'
import IconTarget from '../../../../assets/images/icons-custom/icon-Target.svg'
import IconTime from '../../../../assets/images/icons-custom/Time.svg'
import IconHandshake from '../../../../assets/images/icons-custom/Handshake.svg'
import IconTalkCheck from '../../../../assets/images/icons-custom/TalkCheck.svg'
import IconLock from '../../../../assets/images/icons-custom/Lock.svg'
import IconThumbsUp from '../../../../assets/images/icons-custom/Thumbsup.svg'
import IconLanguages from '../../../../assets/images/icons-custom/Languages.svg'
import IconCost from '../../../../assets/images/icons-custom/Cost.svg'
import IconEveryone from '../../../../assets/images/icons-custom/PlaceForEveryone.svg'
import IconAirplane from '../../../../assets/images/icons-custom/Airplane.svg'
import IconHello from '../../../../assets/images/icons-custom/Hi.svg'

import IconFirst from '../../../../assets/images/icons-custom/First.svg'
import IconSecond from '../../../../assets/images/icons-custom/Second.svg'
import IconThird from '../../../../assets/images/icons-custom/Third.svg'

import IconFrontDesk from '../../../../assets/images/icons-custom/FrontDesk.svg'
import IconProviderAccessInterviews from '../../../../assets/images/icons-custom/ProviderAccessInterviews.svg'
import IconProviderCopyToEpic from '../../../../assets/images/icons-custom/ProviderCopyToEpic.svg'

/* eslint-disable import/prefer-default-export */
export const Icon = styled.span`
  display: inline-block;
  height: 13rem;
  width: 13rem;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  margin-bottom: ${props => (props.margined ? '3rem' : '0')};
  ${props => (props.theme === 'check'
      && `
    background-image: url(${IconCheck});
  `)
    || (props.theme === 'first'
      && `
    background-image: url(${IconFirst});
    height: 16rem;
    width: 16rem;
  `)
    || (props.theme === 'second'
      && `
    background-image: url(${IconSecond});
    height: 16rem;
    width: 16rem;
  `)
    || (props.theme === 'third'
      && `
    background-image: url(${IconThird});
    height: 16rem;
    width: 16rem;
  `) ||
  (props.theme === 'frontDesk' &&
    `
  background-image: url(${IconFrontDesk});
  height: 25rem;
  width: 30rem;
`) ||
  (props.theme === 'providerAccessInterviews' &&
    `
  background-image: url(${IconProviderAccessInterviews});
  height: 25rem;
  width: 30rem;
`) ||
  (props.theme === 'providerCopyToEpic' &&
    `
  background-image: url(${IconProviderCopyToEpic});
  height: 25rem;
  width: 30rem;
`)
    || (props.theme === 'lock'
      && `
    background-image: url(${IconLock});
    background-size: 11rem 11rem;
  `)
    || (props.theme === 'hello'
      && `
    background-image: url(${IconHello});
    background-size: 11rem 11rem;
  `)
    || (props.theme === 'everyone'
      && `
    background-image: url(${IconEveryone});
  `)
    || (props.theme === 'airplane'
      && `
    background-image: url(${IconAirplane});
  `)
    || (props.theme === 'thumbsup'
      && `
    background-image: url(${IconThumbsUp});
    background-size: 12rem 12rem;
  `)
    || (props.theme === 'languages'
      && `
    background-image: url(${IconLanguages});
    height: 13rem;
    width: 17rem;
  `)
    || (props.theme === 'target'
      && `
    background-image: url(${IconTarget});
  `)
    || (props.theme === 'cost'
      && `
    background-image: url(${IconCost});
    height: 7rem;
    width: 13rem;
  `)
    || (props.theme === 'time'
      && `
    background-image: url(${IconTime});
    `)
    || (props.theme === 'handshake'
      && `
    background-image: url(${IconHandshake}); 
    `)
    || (props.theme === 'talkcheck'
      && `
    background-image: url(${IconTalkCheck}); 
    `)
    || null}
`
